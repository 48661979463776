<template>
  <div>
{{table}}
  </div>
</template>

<script>
export default {
    name: "tables",
data() {
    return {
        table: 0
    }
},
// methods: {
//     test() {
        
//     }
// },
mounted() {
    this.table = this.$route.params.id;
    this.$store.state.ws.send(JSON.stringify({event: "insertOrder", data:{
        clientNumber: this.table,
        client: this.$store.state.client,
        route: "",
        type: "table",
        assigned: "",
        status: 0,
        items: [
            
        ],
        expand: false,
        netTotal: 0,
        grossTotal:0 ,
        name: "tafel "+ this.table,
        sort: 0,
        VAT: [],
        totalVAT: 0,
        totalProfit: 0,
        totalCost: 0,
        discount: 0,
        totalDiscount: 0,
        printed: false,
        collis: 0,
        date: this.$store.state.today,
        day: 0,
        month: 0,
        year: 0,
        week: 0,
        quarter: 0,
        notes: "",
        payments: [],
        credit: 0,
        debit: 0,
        created: new Date(),
        


    }}))
},
}
</script>

<style>

</style>